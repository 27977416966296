
























































import { computed, defineComponent, getCurrentInstance, unref, watch } from '@vue/composition-api';
import type { Location } from 'vue-router';

import LoginMenu from '@/modules/auth/components/LoginMenu.vue';
import { ProfileRoute } from '@/modules/profile/types';
import { DashboardRoute } from '@/modules/dashboard/types';
import { NewsRoute } from '@/modules/news/types';
import useCurrentUser from '@/modules/auth/composables/useCurrentUser';
import useRefRich from '@/modules/utils-reactivity/composables/useRefRich';

const appbarLinks: {
  title: string;
  to: Location;
  requireAuth: boolean;
}[] = [
  {
    title: 'News',
    to: { name: NewsRoute.ROOT },
    requireAuth: false,
  },
  {
    title: 'Playlists',
    to: { name: DashboardRoute.ROOT },
    requireAuth: true,
  },
  {
    title: 'Profile',
    to: { name: ProfileRoute.ROOT },
    requireAuth: true,
  },
];

const Appbar = defineComponent({
  name: 'Appbar',
  components: {
    LoginMenu,
  },
  setup() {
    const { ref: expanded, setter: setExpanded } = useRefRich({ value: false });

    const instance = getCurrentInstance();
    const { isLoggedIn } = useCurrentUser();

    const links = computed(() =>
      appbarLinks.filter((link) => !link.requireAuth || unref(isLoggedIn))
    );

    watch(
      () => instance?.proxy.$vuetify.breakpoint.xsOnly,
      (isXsOnly) => {
        if (!isXsOnly && unref(expanded)) setExpanded(false);
      }
    );

    return {
      links,
      expanded,
      setExpanded,
      isLoggedIn,
    };
  },
});

export default Appbar;
