
















































import { defineComponent } from '@vue/composition-api';

import useCurrentUser from '@/modules/auth/composables/useCurrentUser';
import ConfirmDialogSmall from '@/modules/utils/components/ConfirmDialogSmall.vue';
import ProfileCard from './ProfileCard.vue';
import WarningIrreversible from './WarningIrreversible.vue';
import MembershipOfferDialog from './MembershipOfferDialog.vue';

const ProfileAccount = defineComponent({
  name: 'ProfileAccount',
  components: {
    ProfileCard,
    ConfirmDialogSmall,
    MembershipOfferDialog,
    WarningIrreversible,
  },
  setup() {
    const { deleteUser } = useCurrentUser();
    return {
      deleteUser,
    };
  },
});

export default ProfileAccount;
