









































import { defineComponent, onMounted } from '@vue/composition-api';
import * as Sentry from '@sentry/vue';
import noop from 'lodash/noop';

import useRefRich from '@/modules/utils-reactivity/composables/useRefRich';
import useAnalytics from '@/plugins/analytics/composables/useAnalytics';
import { OptionalPromise } from '@moovin-groovin/shared';

interface ConsentPartner {
  id: string;
  name: string;
  description: string;
  enable: () => OptionalPromise<void>;
  disable: () => OptionalPromise<void>;
}

// TODO: Add purposes, and features, as can be seen in examples below
//  - https://www.csoonline.com/article/3202771/general-data-protection-regulation-gdpr-requirements-deadlines-and-facts.html
//  - https://www.theguardian.com/uk
const createConsentPartners = (): ConsentPartner[] => {
  const { analytics } = useAnalytics();

  return [
    {
      id: 'sentry',
      name: 'Sentry',
      description:
        'Error monitoring that helps software teams discover, triage, and prioritize errors in real-time.',
      enable: () => {
        // https://github.com/getsentry/sentry-javascript/issues/2039#issuecomment-486674574
        const clientOptions = Sentry.getCurrentHub().getClient()?.getOptions() ?? {};
        clientOptions.enabled = true;
      },
      disable: () => {
        const clientOptions = Sentry.getCurrentHub().getClient()?.getOptions() ?? {};
        clientOptions.enabled = false;
      },
    },
    {
      id: 'mixpanel',
      name: 'Mixpanel',
      description:
        'User interaction tracking for web and mobile applications. Data collected is used to inform product roadmap, and measure user engagement and retention.',
      enable: () => analytics?.plugins.enable('mixpanel' as any, noop),
      disable: () => analytics?.plugins.enable('mixpanel' as any, noop),
    },
  ];
};

const ConsentAlert = defineComponent({
  name: 'ConsentAlert',
  inheritAttrs: false,
  setup() {
    const { ref: isAlertActive, setter: setIsAlertActive } = useRefRich({ value: true });

    const consentPartners = createConsentPartners();

    const storeConsent = (consentGiven: boolean) =>
      localStorage.setItem('moovin-groovin-consent', JSON.stringify(!!consentGiven));

    const getConsent = (): any =>
      JSON.parse(localStorage.getItem('moovin-groovin-consent') ?? 'null');

    const disableAll = async () => {
      setIsAlertActive(false);
      await Promise.all(consentPartners.map((partner) => partner.disable()));
      storeConsent(false);
    };

    const enableAll = async () => {
      setIsAlertActive(false);
      storeConsent(true);
      await Promise.all(consentPartners.map((partner) => partner.enable()));
    };

    onMounted(() => {
      const consent = getConsent();
      if (consent || consent === false) {
        consent ? enableAll() : disableAll();
        setIsAlertActive(false);
        return;
      }
      setIsAlertActive(true);
    });

    return {
      isAlertActive,
      disableAll,
      enableAll,
    };
  },
});

export default ConsentAlert;
