










import { computed, defineComponent, toRefs, unref, PropType } from '@vue/composition-api';

const NewsArticle = defineComponent({
  name: 'NewsArticle',
  props: {
    date: { type: [Object, String] as PropType<Date | string>, required: false, default: null },
  },
  setup(props) {
    const { date } = toRefs(props);

    const formattedDate = computed(
      (): string => (unref(date) && new Date(unref(date))?.toLocaleDateString()) ?? ''
    );

    return {
      formattedDate,
    };
  },
});

export default NewsArticle;
