/* eslint-disable */
/* This file is autogenerated, see codegen.yml */
export const namedOperations = {
  Query: {
    getCurrentUser: 'getCurrentUser',
    getAllProviders: 'getAllProviders',
    getCurrentUserPlaylists: 'getCurrentUserPlaylists',
    getCurrentUserConfig: 'getCurrentUserConfig'
  },
  Mutation: {
    deleteCurrentUser: 'deleteCurrentUser',
    deleteCurrentUserIntegrations: 'deleteCurrentUserIntegrations',
    logoutCurrentUser: 'logoutCurrentUser',
    updateCurrentUserConfig: 'updateCurrentUserConfig'
  }
}
// Generated on 2021-03-14T17:42:57+00:00
