

















































import { defineComponent, ref, unref } from '@vue/composition-api';

import useListeners from '../composables/useListeners';
import ConfirmDialogSmall from './ConfirmDialogSmall.vue';

const SaveDialogSmall = defineComponent({
  name: 'SaveDialogSmall',
  components: {
    ConfirmDialogSmall,
  },
  inheritAttrs: false,
  setup(_, { emit }) {
    const triggeredDiscard = ref(false);

    const { interceptEvent } = useListeners({ emit });

    const createDiscard = (confirmHandler: () => void) => () => {
      triggeredDiscard.value = true;
      // This triggers the confirm dialog to emit 'confirm' and close dialog
      confirmHandler();
      emit('discard');
    };

    const onConfirm = interceptEvent('confirm', (event, cancelEvent) => {
      if (!unref(triggeredDiscard)) return;
      // Catch and cancel the 'confirm' event that was triggered by discard event handler
      triggeredDiscard.value = false;
      cancelEvent();
    });

    return {
      createDiscard,
      onConfirm,
    };
  },
});

export default SaveDialogSmall;
