








































































import { defineComponent } from '@vue/composition-api';

import useCurrentUser from '@/modules/auth/composables/useCurrentUser';
import { ProfileRoute } from '@/modules/profile/types';

const PrivacyPolicy = defineComponent({
  name: 'PrivacyPolicy',
  setup() {
    const { isLoggedIn } = useCurrentUser();

    return {
      isLoggedIn,
      ProfileRoute,
    };
  },
});

export default PrivacyPolicy;
