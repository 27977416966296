
































































































































import { defineComponent } from '@vue/composition-api';

import useRefRich from '@/modules/utils-reactivity/composables/useRefRich';
import LightboxImg from '@/modules/utils/components/LightboxImg.vue';
import { ProfileRoute } from '../types';

const tagsHeaders = [
  {
    text: 'Name',
    align: 'start',
    sortable: true,
    value: 'name',
  },
  { text: 'Tag', sortable: false, value: 'tag' },
  { text: 'Note', sortable: false, value: 'note' },
];

const tagsItems = [
  {
    name: 'Activity title',
    tag: '{{ activity.title }}',
  },
  {
    name: 'Activity description',
    tag: '{{ activity.description }}',
  },
  {
    name: 'Activity type',
    tag: '{{ activity.type }}',
  },
  {
    name: 'Activity duration',
    tag: '{{ activity.duration }}',
  },
  {
    name: 'Activity date',
    tag: '{{ activity.date }}',
  },
  {
    name: 'Activity URL',
    tag: '{{ activity.url }}',
  },
  {
    name: 'Playlist title',
    tag: '{{ playlist.title }}',
    note: 'Not available in playlist templates',
  },
  {
    name: 'Playlist URL',
    tag: '{{ playlist.url }}',
    note: 'Not available in playlist templates',
  },
  {
    name: 'Playlist tracklist',
    tag: '{{ playlist.tracklist }}',
    note: ['Formatted tracklist.', 'Each track is displayed as "startTime title - artist"'],
  },
  {
    name: 'Playlist tracks',
    tag: '{{ playlist.tracks }}',
    note: [
      'List of playlist tracks.',
      'Each track includes title, album, artist, duration, and startTime',
    ],
  },
  {
    name: 'App name',
    tag: '{{ meta.app }}',
    note: 'Displays "MoovinGroovin"',
  },
];

const TemplateHintDialog = defineComponent({
  name: 'TemplateHintDialog',
  components: {
    LightboxImg,
  },
  inheritAttrs: false,
  setup() {
    const { ref: dialogIsOpen, setter: setDialogIsOpen } = useRefRich({ value: false });

    return {
      dialogIsOpen,
      setDialogIsOpen,
      ProfileRoute,
      tagsHeaders,
      tagsItems,
    };
  },
});

export default TemplateHintDialog;
