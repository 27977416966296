











import { defineComponent } from '@vue/composition-api';

import articles from './articles';

const News = defineComponent({
  name: 'News',
  setup() {
    const isIndexLast = (index: number): boolean => index === articles.length - 1;

    return {
      articles,
      isIndexLast,
    };
  },
});

export default News;
