var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MembershipOfferDialog__wrapper"},[_c('ConfirmDialog',_vm._g(_vm._b({attrs:{"content-class":[
        'MembershipOfferDialog',
        _vm.$vuetify.breakpoint.mdAndUp ? 'MembershipOfferDialog--md' : '' ].join(' ')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_vm._t("activator",[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._t("action",[_vm._v(" Become Premium Member ")])],2)],null,{ on: on, attrs: attrs })]}},{key:"dialog-text",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"MembershipOfferDialog__title"},[_c('v-card-title',[_c('h3',{staticClass:"text-h5 pt-2 px-2"},[_vm._v(" Find the music that takes your performance to the next level ")])]),_c('v-card-subtitle',{staticClass:"pb-0"},[_c('h3',{staticClass:"text-body-1 pt-2 px-2"},[_vm._v("MoovinGroovin is still in alpha development.")])])],1)],1),_c('v-row',{staticClass:"pb-3 px-5"},[_c('v-col',{staticClass:"MembershipOfferDialog__option col-12 col-sm-auto mb-4",class:{ 'px-0': _vm.$vuetify.breakpoint.xsOnly, 'pa-4': _vm.$vuetify.breakpoint.smAndUp }},[_c('v-card',[_c('v-card-title',[_vm._v(" Basic ")]),_c('v-card-subtitle',[_vm._v(" (current) ")]),_c('v-card-text',[_c('ul',[_c('li',[_vm._v("Save activities from past 24 hours")])])])],1)],1),_c('v-col',{staticClass:"MembershipOfferDialog__option col-12 col-sm-auto mb-4",class:{ 'px-0': _vm.$vuetify.breakpoint.xsOnly, 'pa-4': _vm.$vuetify.breakpoint.smAndUp }},[_c('v-card',[_c('v-card-title',[_vm._v(" Early access ")]),_c('v-card-subtitle',[_vm._v(" $5 one time payment ")]),_c('v-card-text',[_c('ul',[_c('li',[_vm._v("Save activities from past 30 days")]),_c('li',[_vm._v("Music vs Performance analytics")]),_c('li',[_vm._v("Track songs on map")]),_c('li',[_vm._v("Tailored Spotify playlists")])])])],1)],1)],1)]},proxy:true},{key:"confirm-text",fn:function(){return [_c('span',{staticClass:"text-body-1"},[_vm._v("Request early access")])]},proxy:true},{key:"cancel-action",fn:function(ref){
      var cancel = ref.cancel;
return [_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":cancel}},[_vm._v(" Cancel ")])]}}],null,true)},'ConfirmDialog',_vm.$attrs,false),Object.assign({}, _vm.$listeners,
      {confirm: _vm.onConfirm,
      cancel: _vm.onCancel}))),_c('MembershipBuyDialog',{attrs:{"value":_vm.buyDialogIsOpen},on:{"cancel":function($event){return _vm.setBuyDialogIsOpen(false)},"confirm":function($event){return _vm.setBuyDialogIsOpen(false)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('div')]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }