



































import { defineComponent } from '@vue/composition-api';

import { BaseRoute } from '../types';

const AppFooter = defineComponent({
  name: 'AppFooter',
  setup() {
    return {
      BaseRoute,
    };
  },
});

export default AppFooter;
