

















































import { defineComponent, unref, toRefs, watch, computed } from '@vue/composition-api';

import useRefRich from '@/modules/utils-reactivity/composables/useRefRich';
import useListeners from '../composables/useListeners';

/** Wrapper around v-dialog that handles open/close logic */
const ConfirmDialog = defineComponent({
  name: 'ConfirmDialog',
  inheritAttrs: false,
  props: {
    value: { type: Boolean, required: false, default: false },
    contentClass: { type: String, required: false, default: '' },
  },
  setup(props, { emit }) {
    const { value, contentClass } = toRefs(props);

    const { ref: internalValue, setter: setInternalValue } = useRefRich({ value });

    const { interceptEvent } = useListeners({ emit });

    watch(value, setInternalValue);
    watch(internalValue, (newVal) => emit('input', newVal));

    const confirm = () => {
      emit('confirm');
      setInternalValue(false);
    };
    const cancel = () => {
      emit('cancel');
      setInternalValue(false);
    };

    const onInput = interceptEvent('input', (event, _, newVal: boolean) =>
      setInternalValue(newVal)
    );

    const onClickOutside = interceptEvent('click:outside', cancel);

    const usedContentClass = computed((): string => `${unref(contentClass)} ConfirmDialog__dialog`);

    return {
      confirm,
      cancel,
      internalValue,
      usedContentClass,
      onInput,
      onClickOutside,
    };
  },
});

export default ConfirmDialog;
