












import { defineComponent } from '@vue/composition-api';

const NotifSnackbarError = defineComponent({
  name: 'NotifSnackbarError',
  inheritAttrs: false,
  props: {
    content: { type: String, required: true },
  },
});

export default NotifSnackbarError;
