import { render, staticRenderFns } from "./Article-2021-03-14-1730-Apple-Music-integration-currently-not-possible.vue?vue&type=template&id=bfe13804&"
import script from "./Article-2021-03-14-1730-Apple-Music-integration-currently-not-possible.vue?vue&type=script&lang=ts&"
export * from "./Article-2021-03-14-1730-Apple-Music-integration-currently-not-possible.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports