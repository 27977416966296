





























import { computed, defineComponent, getCurrentInstance } from '@vue/composition-api';

import pickPrefixed from '@/modules/utils/utils/pickPrefixed';

const ProfileFormSubmit = defineComponent({
  name: 'ProfileFormSubmit',
  inheritAttrs: false,
  props: {
    submitIsLoading: { type: Boolean, required: false, default: false },
    discardIsLoading: { type: Boolean, required: false, default: false },
  },
  setup() {
    const instance = getCurrentInstance();

    const submitBtnAttrs = computed(() => pickPrefixed(instance?.proxy.$attrs ?? {}, 'submit-'));
    const discardBtnAttrs = computed(() => pickPrefixed(instance?.proxy.$attrs ?? {}, 'discard-'));

    return {
      submitBtnAttrs,
      discardBtnAttrs,
    };
  },
});

export default ProfileFormSubmit;
