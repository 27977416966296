


































import { computed, defineComponent, unref } from '@vue/composition-api';

import useCurrentUser from '../composables/useCurrentUser';

const LoginAvatar = defineComponent({
  name: 'LoginAvatar',
  inheritAttrs: false,
  setup() {
    const { user } = useCurrentUser();

    const userInitials = computed(() => {
      const { nameFamily, nameGiven, nameDisplay } = unref(user) ?? {};

      const names = nameFamily && nameGiven ? [nameGiven, nameFamily] : nameDisplay?.split(' ');
      if (!names?.length) return 'You';
      return names.map((name) => name[0]).join('');
    });

    return {
      user,
      userInitials,
    };
  },
});

export default LoginAvatar;
