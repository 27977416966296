











































import { computed, defineComponent, unref } from '@vue/composition-api';
import difference from 'lodash/difference';

import useCurrentUser from '@/modules/auth/composables/useCurrentUser';
import useOpenAuthWindow from '@/modules/auth/composables/useOpenAuthWindow';
import useProviders, { Provider } from '@/modules/auth/composables/useProviders';
import useNotifSnackbar, { NotifType } from '@/modules/utils/composables/useNotifSnackbar';
import ProfileIntegration from './ProfileIntegration.vue';

const ProfileIntegrations = defineComponent({
  name: 'ProfileIntegrations',
  components: {
    ProfileIntegration,
  },
  setup() {
    const { user, deleteIntegrations, refetch: refetchUser } = useCurrentUser();
    const { openAuthWindow } = useOpenAuthWindow();
    const { queueNotif } = useNotifSnackbar();
    const { providersByType } = useProviders();

    const isProviderIntegrated = (provider: Provider) =>
      Boolean(unref(user)?.providers.find((p) => p.providerId === provider.providerId));

    const integrationGroups = computed(() => [
      {
        name: 'Music',
        providers: unref(providersByType).playlist ?? [],
        integrated: unref(providersByType).playlist?.some(isProviderIntegrated),
      },
      {
        name: 'Sport',
        providers: unref(providersByType).activity ?? [],
        integrated: unref(providersByType).activity?.some(isProviderIntegrated),
      },
    ]);

    const deleteIntegration = (providerId: string) =>
      deleteIntegrations({ providerIds: [providerId] });

    const authWindowHandler = (providerId: string) => {
      const oldProviders = [...(unref(user)?.providers ?? [])];

      const onDidCloseWindow = () =>
        refetchUser()
          .then(() => {
            const newProviders = [...(unref(user)?.providers ?? [])];
            if (!newProviders.find((provider) => provider.providerId === providerId)) {
              queueNotif({
                notifType: NotifType.ERROR,
                attrs: {
                  content: 'Failed to add integration',
                },
              });
              return;
            }

            if (difference(newProviders, oldProviders).length) {
              queueNotif({
                notifType: NotifType.CONFIRM,
                attrs: {
                  content: 'Integration added successfully.',
                },
              });
            }
          })
          .catch((err) =>
            queueNotif({
              notifType: NotifType.ERROR,
              attrs: {
                content: `Failed to add integration: ${err.message}`,
              },
            })
          );

      return openAuthWindow(providerId, { onDidCloseWindow });
    };

    const onIntegrationChange = (providerId: string) => {
      authWindowHandler(providerId);
    };

    return {
      deleteIntegration,
      onIntegrationChange,
      integrationGroups,
      isProviderIntegrated,
    };
  },
});

export default ProfileIntegrations;
