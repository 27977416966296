

























































import {
  computed,
  defineComponent,
  ref,
  toRefs,
  unref,
  watch,
  Ref,
  getCurrentInstance,
} from '@vue/composition-api';

import validateTemplate from '@moovin-groovin/shared/src/lib/TemplateFormatter/utils/validateTemplate';
import useListeners from '@/modules/utils/composables/useListeners';
import pickPrefixed from '@/modules/utils/utils/pickPrefixed';
import ProfileFormCheckbox from './ProfileFormCheckbox.vue';
import useRefRich from '@/modules/utils-reactivity/composables/useRefRich';

const tabs = [
  {
    text: 'Template',
    value: 'template',
    textareaDisabled: false,
  },
  {
    text: 'Preview',
    value: 'preview',
    textareaDisabled: true,
  },
];

const ProfileFormTextarea = defineComponent({
  name: 'ProfileFormTextarea',
  components: {
    ProfileFormCheckbox,
  },
  inheritAttrs: false,
  props: {
    checkbox: { type: Boolean, required: false, default: false },
    checkboxInputValue: { type: Boolean, required: false, default: false },
    value: { type: String, required: false, default: undefined },
    defaultValue: { type: String, required: false, default: undefined },
    disabled: { type: Boolean, required: false, default: false },
  },
  setup(props, { emit }) {
    const { value, defaultValue } = toRefs(props);

    const textareaValue: Ref<string | undefined> = ref();
    const tabValue: Ref<any | undefined> = ref();
    const { ref: preview, setter: setPreview } = useRefRich({ value: '' });

    const instance = getCurrentInstance();
    const { interceptEvent, reemitPrefixedEvents } = useListeners({ emit });

    const checkboxAttrs = computed(() => pickPrefixed(instance?.proxy.$attrs ?? {}, 'checkbox-'));
    const checkboxListeners = reemitPrefixedEvents(
      [
        'change',
        'click',
        'click:append',
        'click:prepend',
        'mousedown',
        'mouseup',
        'update:error',
        'update:indeterminate',
      ],
      'checkbox-'
    );

    ///////////////////////////////////////////////////////////
    // Generate preview
    ///////////////////////////////////////////////////////////

    const isPreviewTab = computed((): boolean => tabs[unref(tabValue)]?.value === 'preview');
    watch(isPreviewTab, async (newIsPreviewTab) => {
      if (!newIsPreviewTab || !unref(value)) {
        setPreview('');
        return;
      }
      const { result } = await validateTemplate(unref(value));
      setPreview(result || '');
    });

    ///////////////////////////////////////////////////////////
    // Detect when textarea value differs from default value
    ///////////////////////////////////////////////////////////

    watch(
      value,
      (newValue) => {
        textareaValue.value = newValue;
      },
      { immediate: true }
    );

    const onTextareaInput = interceptEvent('input', (_, __, newValue?: string) => {
      textareaValue.value = newValue;
    });

    const textareaIsDirty = computed((): boolean =>
      Boolean(unref(defaultValue) && unref(textareaValue) !== unref(defaultValue))
    );

    return {
      onTextareaInput,
      textareaIsDirty,
      checkboxAttrs,
      checkboxListeners,
      tabValue,
      tabs,
      isPreviewTab,
      preview,
    };
  },
});

export default ProfileFormTextarea;
