
















import { defineComponent } from '@vue/composition-api';

const ProfileFormCheckbox = defineComponent({
  name: 'ProfileFormCheckbox',
  inheritAttrs: false,
});

export default ProfileFormCheckbox;
