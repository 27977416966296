







import { defineComponent } from '@vue/composition-api';

const WarningIrreversible = defineComponent({
  name: 'WarningIrreversible',
});

export default WarningIrreversible;
