




































import { defineComponent } from '@vue/composition-api';

import ProfileCard from './ProfileCard.vue';

const ProfileIntegration = defineComponent({
  name: 'ProfileIntegration',
  components: {
    ProfileCard,
  },
  props: {
    providerName: { type: String, required: true },
    integrated: { type: Boolean, required: false, default: false },
  },
});

export default ProfileIntegration;
