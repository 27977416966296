















import { defineComponent } from '@vue/composition-api';

import useCurrentUser from '../composables/useCurrentUser';
import LoginAvatar from './LoginAvatar.vue';
import LoginProviders from './LoginProviders.vue';

const LoginMenu = defineComponent({
  name: 'LoginMenu',
  components: {
    LoginAvatar,
    LoginProviders,
  },
  inheritAttrs: false,
  setup() {
    const { user, logout } = useCurrentUser();

    return {
      user,
      logout,
    };
  },
});

export default LoginMenu;
