

















import { computed, defineComponent, unref } from '@vue/composition-api';

import useCurrentUser from '../composables/useCurrentUser';
import useOpenAuthWindow from '../composables/useOpenAuthWindow';
import useProviders from '../composables/useProviders';

const LoginProviders = defineComponent({
  name: 'LoginProviders',
  setup() {
    const { openAuthWindow } = useOpenAuthWindow();
    const { refetch } = useCurrentUser();
    const { providers } = useProviders();

    const loginProviders = computed(() => unref(providers).filter((p) => p.isAuthProvider));

    return {
      loginProviders,
      openAuthWindow,
      refetch,
    };
  },
});

export default LoginProviders;
