























































































import { defineComponent } from '@vue/composition-api';

import ConfirmDialog from '@/modules/utils/components/ConfirmDialog.vue';
import MembershipBuyDialog from './MembershipBuyDialog.vue';
import useRefRich from '@/modules/utils-reactivity/composables/useRefRich';
import useListeners from '@/modules/utils/composables/useListeners';

const MembershipOfferDialog = defineComponent({
  name: 'MembershipOfferDialog',
  components: {
    ConfirmDialog,
    MembershipBuyDialog,
  },
  inheritAttrs: false,
  setup(_, { emit }) {
    const { ref: buyDialogIsOpen, setter: setBuyDialogIsOpen } = useRefRich({ value: false });

    const { interceptEvent } = useListeners({ emit });

    const onConfirm = interceptEvent('confirm', () => setBuyDialogIsOpen(true));
    const onCancel = interceptEvent('cancel', () => setBuyDialogIsOpen(false));

    return {
      buyDialogIsOpen,
      setBuyDialogIsOpen,
      onConfirm,
      onCancel,
    };
  },
});

export default MembershipOfferDialog;
