

























import { defineComponent } from '@vue/composition-api';

import NewsArticle from '../NewsArticle.vue';
import LightboxImg from '@/modules/utils/components/LightboxImg.vue';

const Article = defineComponent({
  /* eslint-disable-next-line */
  name: 'Article-2021-03-07-2100-Dashboard',
  components: {
    NewsArticle,
    LightboxImg,
  },
});

export default Article;
