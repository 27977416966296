










































import { defineComponent } from '@vue/composition-api';

import LightboxImg from '@/modules/utils/components/LightboxImg.vue';

const Home = defineComponent({
  name: 'Home',
  components: {
    LightboxImg,
  },
});

export default Home;
