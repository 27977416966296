





import { defineComponent } from '@vue/composition-api';

const LightboxImg = defineComponent({
  name: 'LightboxImg',
  inheritAttrs: false,
  props: {
    src: { type: String, required: true },
  },
});

export default LightboxImg;
