











import { defineComponent } from '@vue/composition-api';

import usePlaylists from '../composables/usePlaylists';
import DashboardPlaylist from './DashboardPlaylist.vue';

const DashboardPlaylists = defineComponent({
  name: 'DashboardPlaylists',
  components: {
    DashboardPlaylist,
  },
  setup() {
    const { playlists } = usePlaylists();

    return {
      playlists,
    };
  },
});

export default DashboardPlaylists;
