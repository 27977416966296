













import { computed, defineComponent, getCurrentInstance, unref, watch } from '@vue/composition-api';

import useCurrentUser from '@/modules/auth/composables/useCurrentUser';

const AuthGuard = defineComponent({
  name: 'AuthGuard',
  setup() {
    const instance = getCurrentInstance();
    const { isLoggedIn } = useCurrentUser();

    const canSeeContent = computed((): boolean => {
      const { requireAuth = true } = instance?.proxy.$route?.meta ?? {};
      return !requireAuth || unref(isLoggedIn);
    });

    watch(canSeeContent, (newCanSeeContent) => {
      if (!unref(newCanSeeContent)) {
        instance?.proxy.$router.push('/');
      }
    });

    return {
      canSeeContent,
    };
  },
});

export default AuthGuard;
