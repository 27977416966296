

















































































































































import { defineComponent, unref, watch, computed } from '@vue/composition-api';

import validateTemplate from '@moovin-groovin/shared/src/lib/TemplateFormatter/utils/validateTemplate';
import useFormData from '@/modules/utils/composables/useFormData';
import useValidators from '@/modules/utils/composables/useValidators';
import ConfirmDialogGuard from '@/modules/utils/components/ConfirmDialogGuard.vue';
import SaveDialogSmall from '@/modules/utils/components/SaveDialogSmall.vue';
import useCurrentUserConfig, { UserConfig } from '../composables/useCurrentUserConfig';
import ProfileCard from './ProfileCard.vue';
import TemplateHintDialog from './TemplateHintDialog.vue';
import ProfileFormCheckbox from './ProfileFormCheckbox.vue';
import ProfileFormTextarea from './ProfileFormTextarea.vue';
import ProfileFormSubmit from './ProfileFormSubmit.vue';

const ProfilePreferences = defineComponent({
  name: 'ProfilePreferences',
  components: {
    ProfileCard,
    ProfileFormCheckbox,
    ProfileFormTextarea,
    ProfileFormSubmit,
    TemplateHintDialog,
    ConfirmDialogGuard,
  },
  setup() {
    const {
      config,
      updateConfig,
      loading,
      loadingUpdate: updateInProgress,
    } = useCurrentUserConfig();

    const loadingOrWaiting = computed(() => unref(loading));

    const {
      formData: configFormData,
      updateFormData,
      resetFormData,
      hasUnconfirmedChanges,
    } = useFormData<UserConfig>({ defaults: config });

    const doValidateTemplate = async (template: string | null | undefined) => {
      if (!template) return;
      const { error } = await validateTemplate(template);
      if (error) return error.message;
    };

    const { isValid: formIsValid, errors: formDataErrors } = useValidators(configFormData, {
      activityDescriptionTemplate: doValidateTemplate,
      playlistTitleTemplate: doValidateTemplate,
      playlistDescriptionTemplate: doValidateTemplate,
    });

    watch(config, (newConfig) => updateFormData({ ...newConfig }), { immediate: true });

    const onSubmit = () => {
      const formData = unref(configFormData);

      const normFormData: Partial<UserConfig> = {
        ...formData,
        playlistCollaborative: Boolean(formData.playlistCollaborative),
        playlistPublic: Boolean(formData.playlistPublic),
        playlistAutoCreate: Boolean(formData.playlistAutoCreate),
        activityDescriptionEnabled: Boolean(formData.activityDescriptionEnabled),
      };

      updateConfig({ userConfigInput: formData }).then(() => {
        updateFormData(normFormData);
      });
    };

    const onDiscard = () => resetFormData();

    return {
      config,
      configFormData,
      updateFormData,
      resetFormData,
      onSubmit,
      onDiscard,
      hasUnconfirmedChanges,
      updateInProgress,
      loadingOrWaiting,
      SaveDialogSmall,
      formDataErrors,
      formIsValid,
    };
  },
});

export default ProfilePreferences;
