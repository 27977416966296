






import { defineComponent } from '@vue/composition-api';

const NotifSnackbarConfirm = defineComponent({
  name: 'NotifSnackbarConfirm',
  inheritAttrs: false,
  props: {
    content: { type: String, required: true },
  },
});

export default NotifSnackbarConfirm;
