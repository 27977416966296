



















import { defineComponent } from '@vue/composition-api';

const ProfileCard = defineComponent({
  name: 'ProfileCard',
});

export default ProfileCard;
