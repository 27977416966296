




















import { defineComponent } from '@vue/composition-api';

import NewsArticle from '../NewsArticle.vue';

const Article = defineComponent({
  /* eslint-disable-next-line */
  name: 'Article-2021-03-08-2100-Integrations-Incoming',
  components: {
    NewsArticle,
  },
});

export default Article;
