












































import { computed, defineComponent, toRefs, unref, PropType } from '@vue/composition-api';
import isNil from 'lodash/isNil';

import unixTimestampToDate from '@moovin-groovin/shared/src/utils/unixTimestampToDate';
import ProviderIcon from '@/modules/utils/components/ProviderIcon.vue';
import DashboardCard from './DashboardCard.vue';
import type { Playlist } from '../composables/usePlaylists';

const DashboardPlaylist = defineComponent({
  name: 'DashboardPlaylist',
  components: {
    DashboardCard,
    ProviderIcon,
  },
  props: {
    playlist: { type: Object as PropType<Playlist>, required: true },
  },
  setup(props) {
    const { playlist } = toRefs(props);

    const playlistTitle = computed((): string => {
      const { playlistName, activityName } = unref(playlist) ?? {};
      if (playlistName) return playlistName;
      if (activityName) return `Playlist for activity "${activityName}"`;
      return 'Unknown playlist';
    });

    const playlistDate = computed((): string => {
      const { dateCreated } = unref(playlist) ?? {};

      if (isNil(dateCreated)) return '';

      return unixTimestampToDate(dateCreated).toLocaleDateString();
    });

    return {
      playlistTitle,
      playlistDate,
    };
  },
});

export default DashboardPlaylist;
