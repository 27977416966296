































import { defineComponent } from '@vue/composition-api';

const DashboardCard = defineComponent({
  name: 'DashboardCard',
});

export default DashboardCard;
