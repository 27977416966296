var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ConfirmDialog',_vm._g(_vm._b({attrs:{"content-class":"MembershipBuyDialog"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._t("action",[_vm._v(" Become Premium Member ")])],2)],null,{ on: on, attrs: attrs })]}},{key:"dialog-text",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"MembershipBuyDialog__title"},[_c('v-card-title',[_c('h3',{staticClass:"text-h5 pt-2 px-2"},[_vm._v("Thank you for your interest")])])],1)],1),_c('v-row',[_c('v-col',[_c('v-card-text',[_c('p',{staticClass:"text-body-1 font-weight-bold pt-2 px-2"},[_vm._v(" The membership features are still in development. ")]),_c('p',{staticClass:"text-body-1 py-2 px-2"},[_vm._v(" Subscribe to receive product updates and have a say in product roadmap. ")]),_c('v-form',{attrs:{"data-code":"i3l3f1"}},[_c('v-text-field',{staticClass:"text-body-2",attrs:{"type":"text","autocomplete":"name","label":"Name","aria-label":"email","aria-required":"true","filled":"","dense":"","validate-on-blur":"","error-messages":_vm.formDataErrors.name},on:{"input":function (name) { return _vm.updateFormData({ name: name }); },"blur":function($event){return _vm.updateFormData({ name: $event.target.value })}}}),_c('v-text-field',{staticClass:"text-body-2",attrs:{"id":"email","type":"email","autocomplete":"email","label":"Email","hint":"name@example.com","aria-label":"email","aria-required":"true","filled":"","dense":"","error-messages":_vm.formDataErrors.email},on:{"input":function (email) { return _vm.updateFormData({ email: email }); },"blur":function($event){return _vm.updateFormData({ email: $event.target.value })}}})],1)],1)],1)],1)]},proxy:true},{key:"confirm-action",fn:function(ref){
var confirm = ref.confirm;
return [_c('v-btn',{staticClass:"text-body-1",attrs:{"color":"primary","disabled":!_vm.formIsValid || !_vm.isDirty},on:{"click":confirm}},[_vm._v(" Subscribe ")])]}},{key:"cancel-action",fn:function(ref){
var cancel = ref.cancel;
return [_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":cancel}},[_vm._v(" Cancel ")])]}}],null,true)},'ConfirmDialog',_vm.$attrs,false),Object.assign({}, _vm.$listeners, {confirm: _vm.onConfirmSubmit})))}
var staticRenderFns = []

export { render, staticRenderFns }