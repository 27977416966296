






































import { defineComponent } from '@vue/composition-api';

import NewsArticle from '../NewsArticle.vue';

const Article = defineComponent({
  /* eslint-disable-next-line */
  name: 'Article-2021-03-14-1730-Apple-Music-integration-currently-not-possible',
  components: {
    NewsArticle,
  },
});

export default Article;
