
























































import { computed, defineComponent, toRefs, unref } from '@vue/composition-api';

import ConfirmDialog from './ConfirmDialog.vue';

const ConfirmDialogSmall = defineComponent({
  name: 'ConfirmDialogSmall',
  components: {
    ConfirmDialog,
  },
  inheritAttrs: false,
  props: {
    width: { type: String, required: false, default: '350px' },
    contentClass: { type: String, required: false, default: '' },
  },
  setup(props) {
    const { contentClass } = toRefs(props);

    const usedContentClass = computed(
      (): string => `${unref(contentClass)} ConfirmDialogSmall__dialog`
    );

    return {
      usedContentClass,
    };
  },
});

export default ConfirmDialogSmall;
