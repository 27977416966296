
































import { defineComponent } from '@vue/composition-api';

import NewsArticle from '../NewsArticle.vue';
import LightboxImg from '@/modules/utils/components/LightboxImg.vue';

const Article = defineComponent({
  /* eslint-disable-next-line */
  name: 'Article-2021-02-24-1200-Alpha-Release',
  components: {
    NewsArticle,
    LightboxImg,
  },
  setup() {
    const email = 'juro@moovingroovin.com';
    return { email };
  },
});

export default Article;
