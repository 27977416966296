/* eslint-disable */
/* This file is autogenerated, see codegen.yml */
import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
type Maybe<T> = T | null;
type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

type Query = {
  __typename?: 'Query';
  getAllProviders: Array<Provider>;
  getCurrentUser: User;
  getCurrentUserConfig: UserConfig;
  getCurrentUserPlaylists: Array<Playlist>;
  hello: Maybe<Scalars['String']>;
};

type Mutation = {
  __typename?: 'Mutation';
  deleteCurrentUser: User;
  deleteCurrentUserProviders: Array<Maybe<Provider>>;
  hello: Maybe<Scalars['String']>;
  logoutCurrentUser: User;
  updateCurrentUserConfig: UserConfig;
};


type MutationdeleteCurrentUserProvidersArgs = {
  providerIds: Array<Scalars['String']>;
};


type MutationupdateCurrentUserConfigArgs = {
  userConfigInput: UserConfigInput;
};

type Provider = {
  __typename?: 'Provider';
  providerId: Scalars['String'];
  name: Scalars['String'];
  isAuthProvider: Scalars['Boolean'];
  isActivityProvider: Scalars['Boolean'];
  isPlaylistProvider: Scalars['Boolean'];
};

type AuthProvider = 
  | 'FACEBOOK'
  | 'GOOGLE';

type PlaylistProvider = 
  | 'SPOTIFY';

type ActivityProvider = 
  | 'STRAVA';

type UserConfig = {
  __typename?: 'UserConfig';
  /** Whether user playlists should be created as collaborative */
  playlistCollaborative: Scalars['Boolean'];
  /** Whether user playlists should be created as public */
  playlistPublic: Scalars['Boolean'];
  /** Whether user playlists should be created automatically */
  playlistAutoCreate: Scalars['Boolean'];
  /** Template for creating playlist description */
  playlistDescriptionTemplate: Maybe<Scalars['String']>;
  /** Template for creating playlist title */
  playlistTitleTemplate: Maybe<Scalars['String']>;
  /** Whether activity description should be updated after playlist is created */
  activityDescriptionEnabled: Scalars['Boolean'];
  /** Template for creating updated activity description that includes playlist */
  activityDescriptionTemplate: Maybe<Scalars['String']>;
};

type UserConfigInput = {
  /** Whether user playlists should be created as collaborative */
  playlistCollaborative?: Maybe<Scalars['Boolean']>;
  /** Whether user playlists should be created as public */
  playlistPublic?: Maybe<Scalars['Boolean']>;
  /** Whether user playlists should be created automatically */
  playlistAutoCreate?: Maybe<Scalars['Boolean']>;
  /** Template for creating playlist description */
  playlistDescriptionTemplate?: Maybe<Scalars['String']>;
  /** Template for creating playlist title */
  playlistTitleTemplate?: Maybe<Scalars['String']>;
  /** Whether activity description should be updated after playlist is created */
  activityDescriptionEnabled?: Maybe<Scalars['Boolean']>;
  /** Template for creating updated activity description that includes playlist */
  activityDescriptionTemplate?: Maybe<Scalars['String']>;
};

type Playlist = {
  __typename?: 'Playlist';
  playlistProviderId: Scalars['String'];
  playlistId: Scalars['String'];
  playlistUrl: Maybe<Scalars['String']>;
  playlistName: Maybe<Scalars['String']>;
  activityProviderId: Scalars['String'];
  activityId: Scalars['String'];
  activityName: Maybe<Scalars['String']>;
  activityUrl: Maybe<Scalars['String']>;
  dateCreated: Maybe<Scalars['Int']>;
};

type User = {
  __typename?: 'User';
  userId: Scalars['String'];
  email: Maybe<Scalars['String']>;
  nameFamily: Maybe<Scalars['String']>;
  nameGiven: Maybe<Scalars['String']>;
  nameDisplay: Maybe<Scalars['String']>;
  photo: Maybe<Scalars['String']>;
  providers: Array<Provider>;
};

type getCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


type getCurrentUserQuery = (
  { __typename?: 'Query' }
  & { getCurrentUser: (
    { __typename?: 'User' }
    & Pick<User, 'userId' | 'email' | 'nameFamily' | 'nameGiven' | 'nameDisplay' | 'photo'>
    & { providers: Array<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'providerId' | 'name' | 'isActivityProvider' | 'isPlaylistProvider' | 'isAuthProvider'>
    )> }
  ) }
);

type deleteCurrentUserMutationVariables = Exact<{ [key: string]: never; }>;


type deleteCurrentUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteCurrentUser: (
    { __typename?: 'User' }
    & Pick<User, 'userId'>
  ) }
);

type deleteCurrentUserIntegrationsMutationVariables = Exact<{
  providerIds: Array<Scalars['String']> | Scalars['String'];
}>;


type deleteCurrentUserIntegrationsMutation = (
  { __typename?: 'Mutation' }
  & { deleteCurrentUserProviders: Array<Maybe<(
    { __typename?: 'Provider' }
    & Pick<Provider, 'providerId'>
  )>> }
);

type logoutCurrentUserMutationVariables = Exact<{ [key: string]: never; }>;


type logoutCurrentUserMutation = (
  { __typename?: 'Mutation' }
  & { logoutCurrentUser: (
    { __typename?: 'User' }
    & Pick<User, 'userId'>
  ) }
);

type getAllProvidersQueryVariables = Exact<{ [key: string]: never; }>;


type getAllProvidersQuery = (
  { __typename?: 'Query' }
  & { getAllProviders: Array<(
    { __typename?: 'Provider' }
    & Pick<Provider, 'providerId' | 'name' | 'isActivityProvider' | 'isPlaylistProvider' | 'isAuthProvider'>
  )> }
);

type getCurrentUserPlaylistsQueryVariables = Exact<{ [key: string]: never; }>;


type getCurrentUserPlaylistsQuery = (
  { __typename?: 'Query' }
  & { getCurrentUserPlaylists: Array<(
    { __typename?: 'Playlist' }
    & Pick<Playlist, 'playlistProviderId' | 'playlistId' | 'playlistUrl' | 'playlistName' | 'activityProviderId' | 'activityName' | 'activityUrl' | 'dateCreated'>
  )> }
);

type getCurrentUserConfigQueryVariables = Exact<{ [key: string]: never; }>;


type getCurrentUserConfigQuery = (
  { __typename?: 'Query' }
  & { getCurrentUserConfig: (
    { __typename?: 'UserConfig' }
    & Pick<UserConfig, 'playlistCollaborative' | 'playlistPublic' | 'playlistAutoCreate' | 'playlistDescriptionTemplate' | 'playlistTitleTemplate' | 'activityDescriptionEnabled' | 'activityDescriptionTemplate'>
  ) }
);

type updateCurrentUserConfigMutationVariables = Exact<{
  userConfigInput: UserConfigInput;
}>;


type updateCurrentUserConfigMutation = (
  { __typename?: 'Mutation' }
  & { updateCurrentUserConfig: (
    { __typename?: 'UserConfig' }
    & Pick<UserConfig, 'playlistCollaborative' | 'playlistPublic' | 'playlistAutoCreate' | 'playlistDescriptionTemplate' | 'playlistTitleTemplate' | 'activityDescriptionEnabled' | 'activityDescriptionTemplate'>
  ) }
);


 const getCurrentUserDocument = gql`
    query getCurrentUser {
  getCurrentUser {
    userId
    email
    nameFamily
    nameGiven
    nameDisplay
    photo
    providers {
      providerId
      name
      isActivityProvider
      isPlaylistProvider
      isAuthProvider
    }
  }
}
    `;

/**
 * __usegetCurrentUserQuery__
 *
 * To run a query within a Vue component, call `usegetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usegetCurrentUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usegetCurrentUserQuery();
 */
export function usegetCurrentUserQuery(options: VueApolloComposable.UseQueryOptions<getCurrentUserQuery, getCurrentUserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<getCurrentUserQuery, getCurrentUserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<getCurrentUserQuery, getCurrentUserQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<getCurrentUserQuery, getCurrentUserQueryVariables>(getCurrentUserDocument, {}, options);
}
export type getCurrentUserQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<getCurrentUserQuery, getCurrentUserQueryVariables>;
 const deleteCurrentUserDocument = gql`
    mutation deleteCurrentUser {
  deleteCurrentUser {
    userId
  }
}
    `;

/**
 * __usedeleteCurrentUserMutation__
 *
 * To run a mutation, you first call `usedeleteCurrentUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `usedeleteCurrentUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = usedeleteCurrentUserMutation();
 */
export function usedeleteCurrentUserMutation(options: VueApolloComposable.UseMutationOptions<deleteCurrentUserMutation, deleteCurrentUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<deleteCurrentUserMutation, deleteCurrentUserMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<deleteCurrentUserMutation, deleteCurrentUserMutationVariables>(deleteCurrentUserDocument, options);
}
export type deleteCurrentUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<deleteCurrentUserMutation, deleteCurrentUserMutationVariables>;
 const deleteCurrentUserIntegrationsDocument = gql`
    mutation deleteCurrentUserIntegrations($providerIds: [String!]!) {
  deleteCurrentUserProviders(providerIds: $providerIds) {
    providerId
  }
}
    `;

/**
 * __usedeleteCurrentUserIntegrationsMutation__
 *
 * To run a mutation, you first call `usedeleteCurrentUserIntegrationsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `usedeleteCurrentUserIntegrationsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = usedeleteCurrentUserIntegrationsMutation({
 *   variables: {
 *     providerIds: // value for 'providerIds'
 *   },
 * });
 */
export function usedeleteCurrentUserIntegrationsMutation(options: VueApolloComposable.UseMutationOptions<deleteCurrentUserIntegrationsMutation, deleteCurrentUserIntegrationsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<deleteCurrentUserIntegrationsMutation, deleteCurrentUserIntegrationsMutationVariables>>) {
  return VueApolloComposable.useMutation<deleteCurrentUserIntegrationsMutation, deleteCurrentUserIntegrationsMutationVariables>(deleteCurrentUserIntegrationsDocument, options);
}
export type deleteCurrentUserIntegrationsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<deleteCurrentUserIntegrationsMutation, deleteCurrentUserIntegrationsMutationVariables>;
 const logoutCurrentUserDocument = gql`
    mutation logoutCurrentUser {
  logoutCurrentUser {
    userId
  }
}
    `;

/**
 * __uselogoutCurrentUserMutation__
 *
 * To run a mutation, you first call `uselogoutCurrentUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `uselogoutCurrentUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = uselogoutCurrentUserMutation();
 */
export function uselogoutCurrentUserMutation(options: VueApolloComposable.UseMutationOptions<logoutCurrentUserMutation, logoutCurrentUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<logoutCurrentUserMutation, logoutCurrentUserMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<logoutCurrentUserMutation, logoutCurrentUserMutationVariables>(logoutCurrentUserDocument, options);
}
export type logoutCurrentUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<logoutCurrentUserMutation, logoutCurrentUserMutationVariables>;
 const getAllProvidersDocument = gql`
    query getAllProviders {
  getAllProviders {
    providerId
    name
    isActivityProvider
    isPlaylistProvider
    isAuthProvider
  }
}
    `;

/**
 * __usegetAllProvidersQuery__
 *
 * To run a query within a Vue component, call `usegetAllProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `usegetAllProvidersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usegetAllProvidersQuery();
 */
export function usegetAllProvidersQuery(options: VueApolloComposable.UseQueryOptions<getAllProvidersQuery, getAllProvidersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<getAllProvidersQuery, getAllProvidersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<getAllProvidersQuery, getAllProvidersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<getAllProvidersQuery, getAllProvidersQueryVariables>(getAllProvidersDocument, {}, options);
}
export type getAllProvidersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<getAllProvidersQuery, getAllProvidersQueryVariables>;
 const getCurrentUserPlaylistsDocument = gql`
    query getCurrentUserPlaylists {
  getCurrentUserPlaylists {
    playlistProviderId
    playlistId
    playlistUrl
    playlistName
    activityProviderId
    activityName
    activityUrl
    dateCreated
  }
}
    `;

/**
 * __usegetCurrentUserPlaylistsQuery__
 *
 * To run a query within a Vue component, call `usegetCurrentUserPlaylistsQuery` and pass it any options that fit your needs.
 * When your component renders, `usegetCurrentUserPlaylistsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usegetCurrentUserPlaylistsQuery();
 */
export function usegetCurrentUserPlaylistsQuery(options: VueApolloComposable.UseQueryOptions<getCurrentUserPlaylistsQuery, getCurrentUserPlaylistsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<getCurrentUserPlaylistsQuery, getCurrentUserPlaylistsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<getCurrentUserPlaylistsQuery, getCurrentUserPlaylistsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<getCurrentUserPlaylistsQuery, getCurrentUserPlaylistsQueryVariables>(getCurrentUserPlaylistsDocument, {}, options);
}
export type getCurrentUserPlaylistsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<getCurrentUserPlaylistsQuery, getCurrentUserPlaylistsQueryVariables>;
 const getCurrentUserConfigDocument = gql`
    query getCurrentUserConfig {
  getCurrentUserConfig {
    playlistCollaborative
    playlistPublic
    playlistAutoCreate
    playlistDescriptionTemplate
    playlistTitleTemplate
    activityDescriptionEnabled
    activityDescriptionTemplate
  }
}
    `;

/**
 * __usegetCurrentUserConfigQuery__
 *
 * To run a query within a Vue component, call `usegetCurrentUserConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `usegetCurrentUserConfigQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usegetCurrentUserConfigQuery();
 */
export function usegetCurrentUserConfigQuery(options: VueApolloComposable.UseQueryOptions<getCurrentUserConfigQuery, getCurrentUserConfigQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<getCurrentUserConfigQuery, getCurrentUserConfigQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<getCurrentUserConfigQuery, getCurrentUserConfigQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<getCurrentUserConfigQuery, getCurrentUserConfigQueryVariables>(getCurrentUserConfigDocument, {}, options);
}
export type getCurrentUserConfigQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<getCurrentUserConfigQuery, getCurrentUserConfigQueryVariables>;
 const updateCurrentUserConfigDocument = gql`
    mutation updateCurrentUserConfig($userConfigInput: UserConfigInput!) {
  updateCurrentUserConfig(userConfigInput: $userConfigInput) {
    playlistCollaborative
    playlistPublic
    playlistAutoCreate
    playlistDescriptionTemplate
    playlistTitleTemplate
    activityDescriptionEnabled
    activityDescriptionTemplate
  }
}
    `;

/**
 * __useupdateCurrentUserConfigMutation__
 *
 * To run a mutation, you first call `useupdateCurrentUserConfigMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useupdateCurrentUserConfigMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useupdateCurrentUserConfigMutation({
 *   variables: {
 *     userConfigInput: // value for 'userConfigInput'
 *   },
 * });
 */
export function useupdateCurrentUserConfigMutation(options: VueApolloComposable.UseMutationOptions<updateCurrentUserConfigMutation, updateCurrentUserConfigMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<updateCurrentUserConfigMutation, updateCurrentUserConfigMutationVariables>>) {
  return VueApolloComposable.useMutation<updateCurrentUserConfigMutation, updateCurrentUserConfigMutationVariables>(updateCurrentUserConfigDocument, options);
}
export type updateCurrentUserConfigMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<updateCurrentUserConfigMutation, updateCurrentUserConfigMutationVariables>;
// Generated on 2021-03-14T17:42:57+00:00
