

















































































import { defineComponent, onMounted, unref } from '@vue/composition-api';
import * as Sentry from '@sentry/vue';

import ConfirmDialog from '@/modules/utils/components/ConfirmDialog.vue';
import useAnalytics from '@/plugins/analytics/composables/useAnalytics';
import useFormData from '@/modules/utils/composables/useFormData';
import useValidators from '@/modules/utils/composables/useValidators';
import * as validators from '@/modules/utils/utils/validators';
import useListeners from '@/modules/utils/composables/useListeners';

const MembershipBuyDialog = defineComponent({
  name: 'MembershipBuyDialog',
  components: {
    ConfirmDialog,
  },
  inheritAttrs: false,
  setup(_, { emit }) {
    const { trackEvent } = useAnalytics();

    const { interceptEvent } = useListeners({ emit });

    const {
      formData: signupFormData,
      formDataSerialized: serializedSignupFormData,
      updateFormData,
      isDirty,
    } = useFormData<
      {
        name: string;
        email: string;
      },
      { 'fields[email]': string; 'fields[name]': string }
    >({
      defaults: { name: undefined, email: undefined },
      serializer: (data) => ({ 'fields[email]': data.email, 'fields[name]': data.name }),
    });

    const { isValid: formIsValid, errors: formDataErrors } = useValidators(signupFormData, {
      name: [validators.required],
      email: [validators.required, validators.email],
    });

    onMounted(() => trackEvent('membership_interest'));

    const onConfirmSubmit = interceptEvent('confirm', () => {
      const formData = new FormData();
      Object.entries(unref(serializedSignupFormData)).forEach(([key, val]) =>
        formData.set(key, val ?? '')
      );
      // Not sure if these 2 are required, but they are on the singup form by ML
      formData.set('ml-submit', '1');
      formData.set('anticsrf', 'true');

      fetch('https://static.mailerlite.com/webforms/submit/i3l3f1', {
        method: 'POST',
        body: formData,
      }).catch((err) => {
        if (!err) return;
        console.error(err);
        Sentry.captureException(err);
      });
    });

    return {
      formIsValid,
      isDirty,
      formDataErrors,
      updateFormData,
      onConfirmSubmit,
    };
  },
});

export default MembershipBuyDialog;
